import * as React from 'react'

import { Form, Button } from 'tabler-react'

const RegisterUploadResumeForm = props => {

  const {
    errors,
    handleChange,
    handleSubmit,
    isSubmitting
  } = props

  return(
    <React.Fragment>
      <Form onSubmit={handleSubmit}>
        <Form.Group label="Choose resume">
          <Form.FileInput
            name="file"
            placeholder="choose file"
            type="file"
            error={errors && errors.file}
            onChange={handleChange}
          />
          <span className="file-feedback">{errors.file}</span>
        <Form.Group>
        </Form.Group>
          <Button icon="upload" type="submit" block color="primary" loading={isSubmitting}>
            Submit
          </Button>
        </Form.Group>
      </Form>
    </React.Fragment>
  )
}



export default RegisterUploadResumeForm