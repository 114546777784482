import * as React from 'react'

import { Button } from 'tabler-react'

export default class HeaderUnLoggedUser extends React.Component {
  render () {
    return (
      <React.Fragment>
        <Button outline size='sm' color='primary' RootComponent='a' href='https://recruiters.hirexl.in/'>
            Post Job
          </Button>
        <Button className='recruiter-site-btn' link RootComponent='a' href='https://recruiters.hirexl.in/'>Recruiters Site</Button>
      </React.Fragment>
    )
  }
}
