import * as React from 'react'
import { Formik } from 'formik'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { RegisterPage as TablerRegisterPage } from 'tabler-react'

import { registerUser } from '../../actions'

class RegisterPage extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      serverError: ''
    }
  }
  submit (values, {setErrors}) {
    this.setState({
      serverError: ''
    })
    if (!values.terms) {
      setErrors({ terms: 'Please check terms and policy' })
    } else {
      this.props.registerUser(values.name, values.email, values.password)
    }
  }

  componentWillReceiveProps (nextProps) {
    if (nextProps.serverError) {
      this.setState({
        serverError: nextProps.serverError.message
      })
    }
  }

  render () {
    return (
      <Formik
        initialValues={{
          name: (this.props.uploadedResumeUser && this.props.uploadedResumeUser.user) ? this.props.uploadedResumeUser.user.name : '',
          email: (this.props.uploadedResumeUser && this.props.uploadedResumeUser.user) ? this.props.uploadedResumeUser.user.email : '',
          password: '',
          terms: false
        }}
        validate={values => {
        // same as above, but feel free to move this into a class method now.
          let errors = {}

          if (!values.email) {
            errors.email = 'Email is required'
          } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
          ) {
            errors.email = 'Invalid email address'
          }

          if (!values.password) {
            errors.password = 'Password is required'
          }
          if (!values.name) {
            errors.name = 'Name is required'
          }
          return errors
        }}
        onSubmit={(
          values,
          { setSubmitting, setErrors }
        ) => this.submit(values, { setErrors })}
        render={({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting
        }) => (
          <React.Fragment>
            <TablerRegisterPage
              onSubmit={handleSubmit}
              onChange={handleChange}
              onBlur={handleBlur}
              values={values}
              errors={errors}
              touched={touched}
            />
            <span className='error-feedback'>{errors.terms || this.state.serverError}</span>
          </React.Fragment>
        )}
      />
    )
  }
}

function mapStateToProps (state) {
  return {
    serverError: state.serverError,
    uploadedResumeUser: state.uploadedResumeUser
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({ registerUser }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(RegisterPage)
