import * as React from 'react'
import { Modal } from 'react-bootstrap'

export interface Props {
  show: boolean,
  bsSize:string
  handleClose: () => void
}

export default class HireXLModal extends React.Component<Props> {

  render () {
    const bsSize = this.props.bsSize || 'large' 
    return (
      <Modal className='login-modal' bsSize={bsSize} show={this.props.show} onHide={this.props.handleClose}>
        <Modal.Body>
          {this.props.children}
        </Modal.Body>
      </Modal>
    )
  }
}
