import * as React from 'react'
import { connect } from "react-redux"

import AlertComponent from './AlertComponent'
import * as alertActions from './actions'

import './Alert.style.scss'

const AlertContainer = ({
  alert,
  clearAlert
}) => {
  if(!alert.message) {
    return ''
  } 
  return <AlertComponent {...alert} onDismiss={clearAlert} />
}

const mapStateToProps = state => {
  return {
    alert: state.alert
  }
}

const mapDispatchToProps = dispatch => {
  return {
    clearAlert: () => dispatch(alertActions.clear())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AlertContainer)