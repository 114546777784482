import * as React from "react";

import { AccountDropdown } from "tabler-react";

import { ROOT_URL } from "../../actions/types";

interface Props {
  userObj: any;
}

class HeaderLoggedUser extends React.Component<Props> {
  render() {
    const { userObj } = this.props;

    return (
      <React.Fragment>
        <AccountDropdown
          avatarURL={ROOT_URL + userObj.profilePic}
          name={userObj.name}
          description="Jobseeker"
          options={[
            "profile",
            /*   { icon: 'settings', value: 'Settings', to: '/settings' },
                'mail',
                'message', */
            "divider",
            "help",
            { icon: "trash", value: "Delete Account", to: "/delete-account" },
            "logout",
          ]}
        />
      </React.Fragment>
    );
  }
}

export default HeaderLoggedUser;
