const socialCredentials = () => {
  return {
    facebook: {
      appId: "1670103309731594",
    },
    google: {
      appId:
        "1060448671494-sgqd34t3kee04siondn2f9vgibet87qo.apps.googleusercontent.com",
    },
    linkedin: {
      appId: "77g49pra1rlkin",
    },
  };
};

export default socialCredentials;
