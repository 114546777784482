import * as React from 'react'
import { connect } from 'react-redux'

import { bindActionCreators } from 'redux'
import { Button, Form, Text } from 'tabler-react'

import { validateEmail } from '../../utils/Utility'
import { sendForgotPasswordOtpForEmail } from '../../actions'

interface State {
  email: string,
  error: string
  isSubmitting: boolean,
  serverError: string
}

interface Props {
  resetPasswordWithMobile: any
  sendForgotPasswordOtpForEmail: any
}

class ForgotPasswordEmail extends React.Component<Props, State> {
  constructor(props){
    super(props)

    this.handleSubmit = this.handleSubmit.bind(this)

    this.state = {
      email:'',
      error: '',
      isSubmitting: false,
      serverError:''
    }
  }

  componentWillReceiveProps(nextProps){
    if(nextProps.otp && this.state.isSubmitting) {
      this.setState({
        serverError: nextProps.otp.message,
        isSubmitting: false
      })
    }
  }

  handleSubmit(e) {
    e.preventDefault()
    if (validateEmail(this.state.email)){
      this.setState({
        error: '',
        isSubmitting: true
      }, () => {
        this.props.sendForgotPasswordOtpForEmail({
          email: this.state.email
        })
      })
      
    } else {
      this.setState({
        error:'A valid email is required!'
      })
    }
    console.log(validateEmail(this.state.email))
    console.log(this.state)
  }

  render () {
    return (
      <React.Fragment>
        <Form onSubmit={this.handleSubmit}>
          <Form.Group label="Email Address">
            <Form.Input
              name="email"
              placeholder="Enter Email"
              type="text"
              value={this.state.email}
              onChange={(e) => this.setState({email: e.target.value})}
              error={this.state.error}
            />
            <Text className='error-color'>{this.state.serverError}</Text>
          </Form.Group>
          <div className='text-center'>
            <Button type="submit" block color="primary" loading={this.state.isSubmitting} >Request Password Change</Button>
            <Button link onClick={this.props.resetPasswordWithMobile}>Change password with mobile?</Button>
          </div> 
        </Form>
      </React.Fragment>
    )
  }
  }

function mapDispatchToProps(dispatch){
  return bindActionCreators({ sendForgotPasswordOtpForEmail }, dispatch)
}

function mapStateToProps(state) {
  return {
    otp: state.otp
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordEmail)