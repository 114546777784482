import * as React from "react";
import { connect } from "react-redux";

import { Site, Nav, Dimmer } from "tabler-react";

import NavigationLoginItem from "./NavigationLoginItem.react";
import withAuth from "../../utils/withAuth";

interface Props {
  auth: any;
  isLoading: any;
}

class NavigationItems extends React.Component<Props> {
  iterateNavItems(isAuthenticated) {
    let navArray;

    if (isAuthenticated) {
      navArray = [
        {
          name: "Home",
          action: "/"
        },
        {
          name: "Jobs",
          action: "/jobs"
        },
        {
          name: "Blog",
          action: "https://blog.hirexl.in/"
        },
        {
          name: "Applied Jobs",
          action: "/applied-jobs"
        }
      ];
    } else {
      navArray = [
        {
          name: "Home",
          action: "/"
        },
        {
          name: "Jobs",
          action: "/jobs"
        },
        {
          name: "Blog",
          action: "https://blog.hirexl.in/"
        }
      ];
    }
    let navItems = navArray.map((item, i) => {
      return <Nav.Item key={i} value={item.name} to={item.action} />;
    });
    return navItems;
  }

  render() {
    const { isLoading, auth } = this.props;

    const uRL = isLoading
      ? null
      : window.location.pathname == "/profile" && !auth.loggedIn();
    const isAuthenticated = isLoading ? null : auth.loggedIn();
    return (
      <Site.Nav collapse={false}>
        <Dimmer active={isLoading}>
          <Nav>
            {this.iterateNavItems(isAuthenticated)}
            {isAuthenticated && !isLoading ? null : (
              <NavigationLoginItem uRL={uRL} />
            )}
          </Nav>
        </Dimmer>
      </Site.Nav>
    );
  }
}

function mapStateToProps(state) {
  return {
    isLogged: state.auth
  };
}

export default connect(mapStateToProps)(withAuth(NavigationItems));
