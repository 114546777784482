export default {
  title: "Number 1 job portal for fast growing startups| HireXL",
  description:
    "Academic Jobs Curriculum Designing Jobs Education Industry Jobs Ed-tech Jobs Teaching vacancies",
  siteName: "HireXL",
  url: "https://www.hirexl.in",
  openGraph: {
    type: "website",
    locale: "en_IE",
    siteName: "HireXL",
    url: "https://www.hirexl.in/",
    title: "Number 1 job portal for fast growing startups| HireXL",
    description:
      "Academic Jobs Curriculum Designing Jobs Education Industry Jobs Ed-tech Jobs Teaching vacancies",
    images: [
      {
        url: "https://www.hirexl.in/static/images/hxl.jpg",
        width: 800,
        height: 600,
        alt: "HireXL",
        type: "image/jpeg",
      },
      {
        url: "https://www.hirexl.in/static/images/hxl.jpg",
      },
    ],
  },
  additionalMetaTags: [
    {
      name: "theme-color",
      content: "#4981cd",
    },
    {
      name: "apple-mobile-web-app-title",
      content: "HireXL - Hiring Excellence",
    },
    {
      name: "apple-mobile-web-app-status-bar-style",
      content: "default",
    },
    {
      name: "apple-mobile-web-app-capable",
      content: "yes",
    },
    {
      name: "mobile-web-app-capable",
      content: "yes",
    },
    {
      name: "viewport",
      content: "width=device-width, initial-scale=1.0",
    },
    {
      name: "keywords",
      content:
        "analyst,designer,developer,development editor, management manager, subject teacher",
    },
    {
      name: "author",
      content: "HireXL",
    },
    {
      name: "image",
      content: "https://www.hirexl.in/static/images/hxl.jpg",
    },
    {
      property: "fb:app_id",
      content: "1670103309731594",
    },
    {
      name: "image",
      property: "og:image",
      content: "https://www.hirexl.in/static/images/hxl.jpg",
    },
  ],
  twitter: {
    handle: "@hirexl",
    site: "HireXL",
    cardType: "https://www.hirexl.in/static/images/hxl.jpg",
  },
  additionalLinkTags: [
    {
      rel: "icon",
      type: "image/png",
      href: "https://www.hirexl.in/static/images/favicon.ico",
    },
    {
      rel: "manifest",
      href: "/static/manifest/manifest.json",
    },
  ],
};
