import * as React from 'react'

import { Button, Form, Text } from 'tabler-react'

interface State {
  otp: string 
  password: string 
  errors: { otp: string, password: string }
}

interface Props {
  handleResetPassword: any
  isOtpSubmitting: boolean
  serverError: string
}

class ForgotPasswordOtp extends React.Component<Props, State> {
  constructor(props){
    super(props)

    this.handleSubmit = this.handleSubmit.bind(this)

    this.state = {
      otp: '',
      password: '',
      errors : {
        otp:'',
        password:''
      }
    }
  }
  
  handleSubmit (e) {
    e.preventDefault()

    this.setState({
      errors: {
        otp: this.state.otp ? '' : 'OTP is required!',
        password: this.state.password ? '' : 'Password is required!'
      }
    }, () => {
      if(this.state.otp && this.state.password) {
        this.props.handleResetPassword({
          password:this.state.password,
          otp:this.state.otp
        })
      }
    })
  }

  render () {
    const {
      otp,
      password,
      errors
    } = this.state

    return (
      <React.Fragment>
        <Form onSubmit={this.handleSubmit}>
          <Form.Group label="Enter OTP">
            <Form.Input
              name="otp"
              placeholder="Enter OTP"
              type="number"
              value={otp}
              onChange={(e) => this.setState({ otp: e.target.value })}
              error={errors && errors.otp}
            />
          </Form.Group>
          <Form.Group label="New Password">
            <Form.Input
              name="password"
              placeholder="New Password"
              type="password"
              value={password}
              onChange={(e) => this.setState({ password: e.target.value })}
              error={errors && errors.password}
            />
          </Form.Group>
          <Button type="submit" block color="primary" loading={this.props.isOtpSubmitting}>Submit OTP</Button>
          <Text className='error-color'>{this.props.serverError}</Text>
        </Form>
      </React.Fragment>
    )
  }
}

export default ForgotPasswordOtp