import * as React from 'react'

import NavigationItems from './NavigationItem.react'

export default class Navigation extends React.Component {
  render () {
    return (
      <NavigationItems />
    )
  }
}
