import * as React from 'react'
import { connect } from 'react-redux'

import { bindActionCreators } from 'redux'
import { Icon, Card } from 'tabler-react'
import { Modal } from 'react-bootstrap'

import ForgotPasswordEmail from './ForgotPasswordEmail.react'
import ForgotPasswordMobileWithFormik from './ForgotPasswordMobile.react'
import ForgotPasswordOtp from './ForgotPasswordOtp.react'
import { resetPassword } from '../../actions'

export interface Props {
  open: boolean
  handleClose: any
  initialState: boolean
  resetPassword: any
  otp: { status : boolean}
}

export interface State {
  label: string
  serverError: string
  isWithEmail: boolean
  isWithMobile: boolean
  isOtp: boolean
  isOtpSubmitting: boolean
}

class ForgotPassword extends React.Component<Props, State> {
  constructor(props){
    super(props)
    
    this.resetPasswordWithEmailClicked = this.resetPasswordWithEmailClicked.bind(this)
    this.resetPasswordWithMobileClicked = this.resetPasswordWithMobileClicked.bind(this)
    this.handleResetPassword = this.handleResetPassword.bind(this)
    this.closeModal = this.closeModal.bind(this)
    
    this.state = {
      label:'Enter your email address and we will be send OTP to your registered email.',
      isWithEmail: true,
      isWithMobile: false,
      isOtp: false,
      isOtpSubmitting: false,
      serverError: ''
    }
  }

  handleResetPassword (obj) {
    this.props.resetPassword(obj)
    this.setState({
      isOtpSubmitting:true
    })
  }

  resetPasswordWithEmailClicked () {
    this.setState({
      label: 'Enter your mobile number and we will be send OTP to your registered mobile.',
      isWithEmail: false,
      isWithMobile: true,
      isOtp: false
    })
  }

  resetPasswordWithMobileClicked () {
    this.setState({
      label: 'Enter your email address and we will be send OTP to your registered email.',
      isWithEmail: true,
      isWithMobile: false,
      isOtp: false
    })
  }

  componentWillReceiveProps(nextProps) {
    console.log(nextProps)
    if (nextProps.otp && nextProps.otp.status) {
      this.setState({
        label: 'Create new password and enter OTP. We have sent OTP on your register email/mobile',
        isWithEmail: false,
        isWithMobile: false,
        isOtp: true
      })
    }

    if (nextProps.resResetPassword && nextProps.resResetPassword.status) {
      this.props.handleClose()
      this.setState({
        label: 'Enter your email address and we will be send OTP to your registered email.',
        isWithEmail: true,
        isWithMobile: false,
        isOtp: false,
        isOtpSubmitting: false,
        serverError: ''
      })
    }

    if (nextProps.resResetPassword && !nextProps.resResetPassword.status) {
      this.setState({
        serverError: nextProps.resResetPassword.message,
        isOtpSubmitting: false
      })
    }

  
  }

  ForgotPasswordTempate () {
    if (this.state.isWithEmail) {
      return <ForgotPasswordEmail resetPasswordWithMobile={this.resetPasswordWithEmailClicked} />
    } 
    if (this.state.isWithMobile) {
      return <ForgotPasswordMobileWithFormik resetPasswordWithEmail={this.resetPasswordWithMobileClicked } />
    }
    if (this.state.isOtp) {
      return <ForgotPasswordOtp handleResetPassword={this.handleResetPassword} isOtpSubmitting={this.state.isOtpSubmitting} serverError={this.state.serverError}/>
    }
  }

closeModal () {
    this.props.handleClose()
    this.setState({
      label: 'Enter your email address and we will be send OTP to your registered email.',
      isWithEmail: true,
      isWithMobile: false,
      isOtp: false,
      isOtpSubmitting: false,
      serverError: ''
    })
  }

  render () {
    
    return(
      <Modal className='login-modal' show={this.props.open} >
        <Modal.Body>
          <Icon className='close-btn' prefix='fe' name='x' onClick={this.closeModal} />
            <Card className='p-6'>
              <Card.Title>Forgot Password</Card.Title>
            <p className='text-muted'>{this.state.label}</p>
            { this.ForgotPasswordTempate() }
            </Card>
        </Modal.Body>
      </Modal>
    )
  }
}

function mapStateToProps(state) {
  return {
    otp: state.otp,
    resResetPassword: state.resetPassword
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ resetPassword }, dispatch)
}


export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword)
