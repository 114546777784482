import * as React from 'react'

import { Site } from 'tabler-react'

import HeaderBrand from './HeaderBrand.react'

class Header extends React.Component {  
  render () {
    return (
      <Site>
        <HeaderBrand />
      </Site>
    )
  }
}

export default Header
