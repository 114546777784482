import * as React from "react";
import Modal from "../Modal";
import { Grid, Card, Icon, Button } from "tabler-react";

import RegisterPage from "./RegisterPage.react";
import SocialLoginButton from "../SocialLogin";

import socialCredentials from "../../utils/socialCredentials";

export interface Props {
  onLoginLinkClicked: () => void;
  handleClose: () => void;
  handleUploadResume: any;
  open: boolean;
}

export interface State {
  facebook: string;
  linkedin: string;
  google: string;
}

class RegisterModal extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      facebook: socialCredentials().facebook.appId,
      google: socialCredentials().google.appId,
      linkedin: socialCredentials().linkedin.appId,
    };
  }

  render() {
    return (
      <React.Fragment>
        <Modal
          show={this.props.open}
          handleClose={this.props.handleClose}
          bsSize="large"
        >
          <Icon
            className="close-btn"
            prefix="fe"
            name="x"
            onClick={this.props.handleClose}
          />
          <Grid.Row cards deck className="register">
            <Grid.Col xl={6} lg={6} md={6} sm={6} xs={12} className="form">
              <Card className="register-card">
                <RegisterPage />
                <p className="text-center link-btn">
                  Already have an account ?
                  <Button link onClick={this.props.onLoginLinkClicked}>
                    Login
                  </Button>
                </p>
              </Card>
            </Grid.Col>
            <Grid.Col
              xl={6}
              lg={6}
              md={6}
              sm={6}
              xs={12}
              className="social-card"
            >
              <Card className="mb-0">
                <Card.Body className="text-center social-connect-btn">
                  <p> Connect with Social network</p>
                  <Grid.Col xl={12} lg={12} md={12} sm={12} xs={12}>
                    <SocialLoginButton
                      provider={"facebook"}
                      appId={this.state.facebook}
                    >
                      <Icon
                        prefix="fa"
                        className="social-ico"
                        name="facebook-square"
                      />
                    </SocialLoginButton>
                    {/* <SocialLoginButton
                      provider={"linkedin"}
                      appId={this.state.linkedin}
                    >
                      <Icon
                        prefix="fa"
                        className="social-ico"
                        name="linkedin-square"
                      />
                    </SocialLoginButton> */}
                    <SocialLoginButton
                      provider={"google"}
                      appId={this.state.google}
                    >
                      <Icon
                        prefix="fa"
                        className="social-ico"
                        name="google-plus-square"
                      />
                    </SocialLoginButton>
                    <p className="mb-2">OR</p>
                  </Grid.Col>
                  <Grid.Col xl={12} lg={12} md={12} sm={12} xs={12}>
                    <Button
                      color="gray"
                      icon="upload"
                      onClick={this.props.handleUploadResume}
                    >
                      Upload resume
                    </Button>
                    <p>We will fill your details</p>
                  </Grid.Col>
                </Card.Body>
              </Card>
            </Grid.Col>
          </Grid.Row>
        </Modal>
      </React.Fragment>
    );
  }
}

export default RegisterModal;
