import * as React from "react";
import { Grid, List } from "tabler-react";

export interface navObj {
  title: string;
  items: string[];
  color?: string;
}

export interface Props {
  navObj: navObj;
}

class FooterLinks extends React.Component<Props> {
  constructor(props) {
    super(props);
  }

  listItem() {
    const itemArray = this.props.navObj.items;
    return itemArray.map((item: any) => {
      return (
        <List.Item key={item}>
          <a href={item.link} style={{ color: "#FFFFFF" }}>
            {item.label}
          </a>
        </List.Item>
      );
    });
  }

  render() {
    return (
      <React.Fragment>
        <Grid.Row>
          <Grid.Col lg={8}>
            <div className="xs-address-footer">
              <div className="footer-title">{this.props.navObj.title}</div>
              <List unstyled className="footer-link">
                {this.listItem()}
              </List>
            </div>
          </Grid.Col>
        </Grid.Row>
      </React.Fragment>
    );
  }
}

export default FooterLinks;
