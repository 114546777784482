export default class AuthService {
  constructor(){
    this.getProfile = this.getProfile.bind(this)
  }

  setProfile(profile) {
      // Saves profile data to localStorage
      localStorage.setItem('profile', JSON.stringify(profile))
  }

  getProfile() {
    // Retrieves the profile data from localStorage
    const profile = localStorage.getItem('profile')
    return profile ? JSON.parse(profile) : {}
  }

  setToken(idToken) {
    // Saves user token to localStorage
    localStorage.setItem('authToken', idToken)
  }

  getToken() {
    // Retrieves the user token from localStorage
    return localStorage.getItem('authToken')
  }

  loggedIn() {
    // Checks if there is a saved token and it's still valid
    const token = this.getToken()
    return !!token
  }

  logout() {
    // Clear user token and profile data from localStorage
    localStorage.removeItem('authToken');
    localStorage.removeItem('profile');
  }

  _checkStatus(response) {
    // raises an error in case response status is not a success
    if (response.status >= 200 && response.status < 300) {
      return response
    } else {
      var error = new Error(response.statusText)
      error.response = response
      throw error
    }
  }

  fetch(url, options) {
    // performs api calls sending the required authentication headers
    const headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }

    if (this.loggedIn()) {
      headers['Authorization'] = this.getToken()
    }

    return fetch(url, {
      headers,
      ...options
    })
      .then(this._checkStatus)
      .then(response => response.json())
  }
  
 }