import * as React from "react";

import Head from "next/head";

import {
  SeacrchEngineWrapper,
  OrganizationSEO,
  JobPostingSEO,
} from "../../lib/seo";
import Header from "../Header";
import Footer from "../Footer";
import Navigation from "../Navigation";
import AlertContainer from "../Alert";

export default ({
  children,
  title,
  description,
  jobPosting,
  organization,
  jobPostingSchema,
  organizationSchema,
}) => (
  <React.Fragment>
    {/* Base SEO of the page and whole website */}
    <SeacrchEngineWrapper
      title={title}
      openGraph={{ title }}
      description={description}
    />

    <Head>
      <meta charSet="utf-8" />
      <link
        async
        href="https://fonts.googleapis.com/css?family=Montserrat:900i"
        rel="stylesheet"
      />
      <link
        async
        href="https://fonts.googleapis.com/css?family=Source+Sans+Pro"
        rel="stylesheet"
      />
      <link rel="stylesheet" href="/static/mini/all.css" />
      <link
        rel="stylesheet"
        async
        href="https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css"
      />
      <script type="text/javascript" src="static/drift-widget.js" />
      <script type="text/javascript" src="static/leadfeeder.js" />
    </Head>
    <AlertContainer />
    <Header />
    <Navigation />
    <div className="hirexl-wrapper">{children}</div>
    <Footer />
    {/* SEO: JSON+LD with structured data */}
    {jobPosting && <JobPostingSEO data={jobPosting} />}
    {organization && <OrganizationSEO organization={organization} />}
  </React.Fragment>
);
