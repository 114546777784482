import axios from "axios";
import fetch from "isomorphic-unfetch";

import setAuthorizationToken from "../utils/setAuthorizationToken";
import {
  FETCH_FUNCTIONAL_AREA_FILTER,
  FETCH_FUNCTIONAL_AREA,
  SET_CURRENT_USER,
  SERVER_ERROR,
  SEND_FORGOT_PASSWORD_OTP,
  REGISTER_UPLOAD_RESUME,
  RESET_PASSWORD,
  GET_USER_PROFILE,
  GET_PROFILE_IMAGE,
  UPDATE_PROFILE_IMAGE,
  GET_PERSONAL_INFO,
  ADD_PERSONAL_INFO,
  GET_USER_RESUME,
  ADD_ACHIEVEMENTS,
  ADD_EDUCATION,
  ADD_USER_RESUME,
  DELETE_EDUCATION,
  UPDATE_EDUCATION,
  ADD_EXPERIENCE,
  DELETE_EXPERIENCE,
  UPDATE_EXPERIENCE,
  ADD_USER_SKILLS,
  APPLY_WITHOUT_REGISTRATION,
  APPLY_FOR_JOB,
  GET_USER_JOB_STATUS,
  FETCH_JOB_APPLICATION,
  OPEN_ALERT,
  SET_PASSWORD,
  ROOT_URL,
  BACKEND_URL,
} from "./types";

import * as alertActions from "../components/Alert/actions";

/**
 * @type: Action
 * @description: Login user into HireXL portal
 */

export function loginUser(email, password) {
  const url = `${ROOT_URL}/api/login`;
  const request = axios.post(url, {
    email,
    password,
  });

  return (dispatch) => {
    return request
      .then((res) => {
        if (res.data.status) {
          localStorage.setItem("authToken", res.data.token);
          localStorage.setItem("profile", JSON.stringify(res.data.user));
          setAuthorizationToken(res.data.token);
          dispatch(setCurrentUser(res.data.user));
        } else {
          dispatch(serverError(res.data));
        }
        return res;
      })
      .catch((e) => {
        console.log(e);
      });
  };
}

/**
 * @type: Action
 * @description: Set Current user from sessoion
 */

export function setCurrentUser(profile) {
  return {
    type: SET_CURRENT_USER,
    profile,
  };
}

/**
 * @type: Action
 * @description: Trigger Server error
 */

export function serverError(error) {
  return {
    type: SERVER_ERROR,
    error,
  };
}

/**
 * @type: Action
 * @description: Fetch functional areas with sub-categiries
 */

export function getFilters() {
  const url = `${ROOT_URL}/api/functional-area-filter`;
  const request = axios.get(url);
  return {
    type: FETCH_FUNCTIONAL_AREA_FILTER,
    payload: request,
  };
}

/**
 * @type: Action
 * @description: Fetch functional area's subcategories
 */

export function getFunctionalArea() {
  const url = `${ROOT_URL}/api/functional-area`;
  const request = axios.get(url);
  return {
    type: FETCH_FUNCTIONAL_AREA,
    payload: request,
  };
}

/**
 * @type: Action
 * @description: Logout from HireXL and delete user from localstorage
 */

export function logout() {
  return (dispatch) => {
    localStorage.clear();
    setAuthorizationToken(false);
    dispatch(setCurrentUser({}));
  };
}

/**
 * @type: Action
 * @description: Register jobseeker User into HireXL
 */

export function registerUser(name, email, password) {
  const url = `${ROOT_URL}/api/register`;
  const request = axios.post(url, {
    name,
    email,
    password,
  });
  return (dispatch) => {
    request
      .then((res) => {
        if (res.data.status) {
          localStorage.setItem("authToken", res.data.token);
          localStorage.setItem("profile", JSON.stringify(res.data.user));
          setAuthorizationToken(res.data.token);
          dispatch(setCurrentUser(res.data.user));
        } else {
          dispatch(serverError(res.data));
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
}

export function socialLogin(profile) {
  const url = `${ROOT_URL}/api/social`;
  const request = axios.post(url, profile);
  return (dispatch) => {
    request
      .then((res) => {
        if (res.data.status) {
          localStorage.setItem("authToken", res.data.token);
          localStorage.setItem("profile", JSON.stringify(res.data.user));
          setAuthorizationToken(res.data.token);
          dispatch(setCurrentUser(res.data.user));
        } else {
          dispatch(serverError(res.data));
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
}

/**
 * @type: Action
 * @description: Send forgot password OTP within email to reset password
 */

export function sendForgotPasswordOtpForEmail(email) {
  const url = `${ROOT_URL}/api/password/email`;
  const request = axios.post(url, email);
  return {
    type: SEND_FORGOT_PASSWORD_OTP,
    payload: request,
  };
}

/**
 * @type: Action
 * @description: Reset password
 */

export function resetPassword(reqObj) {
  const url = `${ROOT_URL}/api/password/reset`;
  const request = axios.post(url, reqObj);
  return {
    type: RESET_PASSWORD,
    payload: request,
  };
}

/**
 * @type: Action
 * @description: Register with resume upload
 */

export function registerUploadResume(file) {
  const url = `${ROOT_URL}/api/register/resume`;
  const formData = new FormData();
  formData.append("resume", file);
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  const request = axios.post(url, formData, config);
  return {
    type: REGISTER_UPLOAD_RESUME,
    payload: request,
  };
}

/**
 * @type: Action
 * @description: Fetch User profile from database
 */

export function getUserProfile() {
  const url = `${ROOT_URL}/api/user/profile`;
  const request = axios.get(url);
  return {
    type: GET_USER_PROFILE,
    payload: request,
  };
}

/**
 * @type: Action
 * @description: Get profile image
 */

export function getUserProfileImage() {
  const url = `${ROOT_URL}/api/profile/image`;
  const request = axios.get(url);

  return {
    type: GET_PROFILE_IMAGE,
    payload: request,
  };
}

/**
 * @type: Action
 * @description: Update Profile image
 */

export function updateProfileImage(imageObj) {
  const url = `${ROOT_URL}/api/profile/image`;
  const formData = new FormData();
  formData.append("image", imageObj.file);
  formData.append("src", imageObj.src);
  formData.append("pixelCrop", JSON.stringify(imageObj.pixelCrop));
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  const request = axios.post(url, formData, config);
  return {
    type: UPDATE_PROFILE_IMAGE,
    payload: request,
  };
}

/**
 * @type: Action
 * Get personal information of jobseeker
 * */

export function getPersonalInfo() {
  const url = `${ROOT_URL}/api/user/personalinfo`;
  const request = axios.get(url);
  return {
    type: GET_PERSONAL_INFO,
    payload: request,
  };
}

/**
 * @type: Action
 * @description: Add/Update personal information of user
 */

export function addPersonalInfo(personalinfo) {
  const url = `${ROOT_URL}/api/user/addinfo`;
  const request = axios.post(url, personalinfo);
  return {
    type: ADD_PERSONAL_INFO,
    payload: request,
  };
}

/**
 * @type: Action
 * @description: Get user uploaded resume
 */

export function getUserUploadedResume() {
  const url = `${ROOT_URL}/api/resume`;
  const request = axios.get(url);
  return {
    type: GET_USER_RESUME,
    payload: request,
  };
}

/**
 * @type: Action
 * @description: Update user resume
 */

export function updateUserResume(file) {
  const url = `${ROOT_URL}/api/update/resume`;
  const formData = new FormData();
  formData.append("resume", file);
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  const request = axios.post(url, formData, config);
  return {
    type: ADD_USER_RESUME,
    payload: request,
  };
}

/**
 * @type: Action
 * @description: Add Achievements
 */

export function addAchievements(achievement) {
  const url = `${ROOT_URL}/api/achievement`;
  const request = axios.post(url, achievement);
  return {
    type: ADD_ACHIEVEMENTS,
    payload: request,
  };
}

/**
 * @type: Action
 * @description: Add Education
 */

export function addEducation(education) {
  const url = `${ROOT_URL}/api/user/education`;
  const request = axios.post(url, education);
  return {
    type: ADD_EDUCATION,
    payload: request,
  };
}

/**
 * @type: Action
 * @description: Add Delete Education
 */

export function deleteEducation(education) {
  const url = `${ROOT_URL}/api/user/education/delete`;
  const request = axios.post(url, education);
  return {
    type: DELETE_EDUCATION,
    payload: request,
  };
}

/**
 * @type: Action
 * @description: Add Delete Education
 */

export function updateEducation(education) {
  const url = `${ROOT_URL}/api/user/education/update`;
  const request = axios.post(url, education);
  return {
    type: UPDATE_EDUCATION,
    payload: request,
  };
}

/**
 * @type: Action
 * @description: Add Experience
 */

export function addExperience(experience) {
  const url = `${ROOT_URL}/api/user/experience`;
  const request = axios.post(url, experience);
  return {
    type: ADD_EXPERIENCE,
    payload: request,
  };
}

/**
 * @type: Action
 * @description: Delete Experience
 */

export function deleteExperience(experience) {
  const url = `${ROOT_URL}/api/user/experience/delete`;
  const request = axios.post(url, experience);
  return {
    type: DELETE_EXPERIENCE,
    payload: request,
  };
}

/**
 * @type: Action
 * @description: Update Experience
 */

export function updateExperience(experience) {
  const url = `${ROOT_URL}/api/user/experience/update`;
  const request = axios.post(url, experience);
  return {
    type: UPDATE_EXPERIENCE,
    payload: request,
  };
}

/**
 * @type: Action
 * @description: Add/Update User Skills
 */

export function addUserSkills(userSkills) {
  const url = `${ROOT_URL}/api/userskills`;
  const request = axios.post(url, userSkills);
  return {
    type: ADD_USER_SKILLS,
    payload: request,
  };
}

/**
 * @type: Action
 * @description: Apply for the job without registration
 */

export function applyWithoutRegistration(applicationObj) {
  const url = `${BACKEND_URL}/api/v1/job-application/direct-apply`;
  const formData = new FormData();
  formData.append("resume", applicationObj.files);
  formData.append("name", applicationObj.name);
  formData.append("email", applicationObj.email);
  formData.append("mobile", applicationObj.mobile);
  formData.append("jobId", applicationObj.job);

  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  const request = axios.post(url, formData, config);
  return {
    type: APPLY_WITHOUT_REGISTRATION,
    payload: request,
  };
}

/**
 * @type: Action
 * @description: Get jobs based on filter
 */

export function applyForJob(applicationObj) {
  const url = `${ROOT_URL}/api/job/apply`;
  const request = axios.post(url, applicationObj);
  return {
    type: APPLY_FOR_JOB,
    payload: request,
  };
}

/**
 * @type: Action
 * @description: Get jobs status
 */

export function userJobStatus(jobObj) {
  const url = `${ROOT_URL}/api/job-status`;
  const request = axios.post(url, jobObj);
  return {
    type: GET_USER_JOB_STATUS,
    payload: request,
  };
}

/**
 * @type: Action
 * @description: Get jobs applied applications
 */

export function getAppliedApplication(jobObj) {
  const url = `${ROOT_URL}/api/job/application`;
  const request = axios.get(url);
  return {
    type: FETCH_JOB_APPLICATION,
    payload: request,
  };
}

export function openAlert(obj) {
  return (dispatch) => {
    dispatch(alertActions.set(obj));
  };
}

export function setPassword(obj) {
  const url = `${ROOT_URL}/api/setup/password`;
  const request = axios.post(url, obj);
  return {
    type: SET_PASSWORD,
    payload: request,
  };
}

export function resetJobApplyForm() {
  return (dispatch) => {
    dispatch(initializeApplyForm());
  };
}

export function resetJobApplyModal() {
  return (dispatch) => {
    dispatch(initializeApplyModal());
  };
}

export function initializeApplyModal() {
  return {
    type: SET_PASSWORD,
    payload: {},
  };
}

export function initializeApplyForm() {
  return {
    type: APPLY_WITHOUT_REGISTRATION,
    payload: {},
  };
}

export function deleteUser() {
  const url = `${ROOT_URL}/api/delete-user`;
  return new Promise((resolve, reject) => {
    axios({
      method: "PUT",
      url,
      headers: {
        authorization: localStorage.getItem("authToken"),
      },
    })
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
