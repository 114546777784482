import * as React from "react";
import { connect } from "react-redux";

import { bindActionCreators } from "redux";

import SocialButton from "./SocialButton";
import { socialLogin } from "../../actions";

export interface Props {
  provider: any;
  appId: string;
  socialLogin: Function;
}

class SocialLoginButton extends React.Component<Props> {
  constructor(props) {
    super(props);

    this.handleSocialLogin = this.handleSocialLogin.bind(this);
    this.handleSocialLoginFailure = this.handleSocialLoginFailure.bind(this);
  }

  handleSocialLogin(user) {
    this.props.socialLogin(user);
  }

  handleSocialLoginFailure(err) {
    console.error(err);
  }

  render() {
    return (
      <SocialButton
        provider={this.props.provider}
        appId={this.props.appId}
        autoCleanUri
        onLoginSuccess={this.handleSocialLogin}
        onLoginFailure={this.handleSocialLoginFailure}
      >
        {this.props.children}
      </SocialButton>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ socialLogin }, dispatch);
}

export default connect(null, mapDispatchToProps)(SocialLoginButton);
