import axios from 'axios'

export default function setAuthorizationToken (token) {
  if (token) {
    axios.defaults.headers.common['Authorization'] = `${token}`
    axios.defaults.headers.common['Accept'] = 'application/json'
    axios.defaults.headers.common['Content-Type'] = 'application/json'
  } else {
    delete axios.defaults.headers.common['Authorization']
  }
}
