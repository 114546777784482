import * as React from 'react'
import { Icon } from 'tabler-react'

function FooterSocial () {
  return (
    <React.Fragment >
      <div className='xs-address-footer'>
        <div className='footer-title'>Stay Tuned </div>
        <p>Connect with us and stay in the loop  </p>
        <a className='theme-color-white' target='_blank' href="https://www.facebook.com/Hirexl-351931271893747/">
          <Icon prefix='fa' className='social-ico' name='facebook-square' color='secondary' />
        </a>
        <a className='theme-color-white' target='_blank' href="https://www.linkedin.com/company/25050254/">
          <Icon prefix='fa' className='social-ico' name='linkedin-square' color='secondary' />
        </a>
      </div>
    </React.Fragment>
  )
}

export default FooterSocial
