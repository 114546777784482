import * as React from 'react'

import { Alert } from 'tabler-react'

const AlertComponent = ({
  show,
  type,
  onDismiss,
  message
}) => (
  <Alert
    className={"alert animated " + (show ? "fadeInDown" : "fadeOutUp")}
    bsStyle={type}
    type={type}
    onDismiss={onDismiss}
    isDismissible
   >
    <div className="text-center">{message}</div>
  </Alert>
)

export default AlertComponent