import * as React from 'react'

import { withFormik } from 'formik';
import * as Yup from 'yup'
import { Button, Form } from 'tabler-react'

export interface Props {
  resetPasswordWithEmail: Function,
  values,
  touched,
  errors,
  dirty,
  isSubmitting,
  handleChange,
  handleBlur,
  handleSubmit,
  handleReset,
}

const ForgotPasswordMobile = props => {
  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleSubmit
  } = props

  return(
    <React.Fragment>
      <Form onSubmit={handleSubmit}>
        <Form.Group label="Mobile">
          <Form.Input
            name="mobile"
            placeholder="Enter Mobile"
            type="text"
            value={values.mobile}
            onChange={handleChange}
            error={errors && errors.mobile}
            onBlur={handleBlur}
            className={errors.mobile && touched.mobile ? 'text-input error' : 'text-input'}
          />
        </Form.Group>
        <Button block type="submit" color="primary">Request Password Change</Button>
      </Form>
    </React.Fragment>
  )
}

const WithValidation = withFormik({
  mapPropsToValues: () => ({ mobile: '' }),
  validationSchema: Yup.object().shape({
    mobile: Yup.string()
      .matches(/^[1-9]\d{9}$|^[1-9]\d{9}$/, 'Invalid Mobile Number')
      .required('mobile is required!'),
  }),
  handleSubmit: (values, { }) => {
    console.log(values)
  }
})(ForgotPasswordMobile)

const ForgotPasswordMobileWithFormik = props => {
  return (
    <React.Fragment>
      <WithValidation />
      <Button link onClick={props.resetPasswordWithEmail} >Change password with Email?</Button>
    </React.Fragment>
  )
}

export default ForgotPasswordMobileWithFormik