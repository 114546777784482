import * as React from "react";

import { Grid, Card, Icon, Button } from "tabler-react";

import Modal from "../Modal";
import LoginPage from "./LoginPage.react";
import SocialLoginButton from "../SocialLogin";
import socialCredentials from "../../utils/socialCredentials";

export interface Props {
  onRegisterLinkClicked: () => void;
  handleClose: () => void;
  onForgotPasswordClicked: () => void;
  open: boolean;
}

export interface State {
  facebook: string;
  linkedin: string;
  google: string;
}

class LoginModal extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      facebook: socialCredentials().facebook.appId,
      google: socialCredentials().google.appId,
      linkedin: socialCredentials().linkedin.appId,
    };
  }

  render() {
    return (
      <Modal
        show={this.props.open}
        handleClose={this.props.handleClose}
        bsSize="large"
      >
        <Icon
          className="close-btn"
          prefix="fe"
          name="x"
          onClick={this.props.handleClose}
        />
        <Grid.Row cards deck className="login">
          <Grid.Col xl={6} lg={6} md={6} sm={6} xs={12} className="form">
            <Card className="register-card">
              <LoginPage />
              <Button
                link
                className="forgot-password-link"
                onClick={this.props.onForgotPasswordClicked}
              >
                Forgot Password
              </Button>
              <p className="text-center link-btn">
                New to HireXL?
                <Button link onClick={this.props.onRegisterLinkClicked}>
                  Create Account
                </Button>
              </p>
            </Card>
          </Grid.Col>
          <Grid.Col xl={6} lg={6} md={6} sm={6} xs={12} className="social-card">
            <Card className="social-login-card">
              <Card.Body className="text-center social-connect-btn">
                <p> Connect with Social network</p>
                <Grid.Col xl={12} lg={12} md={12} sm={12} xs={12}>
                  <SocialLoginButton
                    provider={"facebook"}
                    appId={this.state.facebook}
                  >
                    <Icon
                      prefix="fa"
                      className="social-ico"
                      name="facebook-square"
                    />
                  </SocialLoginButton>
                  {/* <SocialLoginButton
                    provider={"linkedin"}
                    appId={this.state.linkedin}
                  >
                    <Icon
                      prefix="fa"
                      className="social-ico"
                      name="linkedin-square"
                    />
                  </SocialLoginButton> */}
                  <SocialLoginButton
                    provider={"google"}
                    appId={this.state.google}
                  >
                    <Icon
                      prefix="fa"
                      className="social-ico"
                      name="google-plus-square"
                    />
                  </SocialLoginButton>
                </Grid.Col>
              </Card.Body>
            </Card>
          </Grid.Col>
        </Grid.Row>
      </Modal>
    );
  }
}

export default LoginModal;
