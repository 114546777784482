export const LOGIN_USER = "LOGIN_USER";
export const OPEN_LOGIN = "OPEN_LOGIN";
export const REGISTER_USER = "REGISTER_USER";
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const FACEBOOK_LOGIN = "FACEBOOK_LOGIN";
export const SOCIAL_LOGIN = "SOCIAL_LOGIN";
export const FETCH_JOBS = "FETCH_JOBS";
export const FETCH_JOB_APPLICATION = "FETCH_JOB_APPLICATION";
export const APPLY_JOB_FILTER = "APPLY_JOB_FILTER";
export const FETCH_FUNCTIONAL_AREA = "FETCH_FUNCTIONAL_AREA";
export const FETCH_FUNCTIONAL_AREA_FILTER = "FETCH_FUNCTIONAL_AREA_FILTER";
export const FETCH_JOB_DETAILS = "FETCH_JOB_DETAILS";
export const FETCH_CITIES = "FETCH_CITIES";
export const LOGOUT_USER = "LOGOUT_USER";
export const SERVER_ERROR = "SERVER_ERROR";
export const SEND_FORGOT_PASSWORD_OTP = "SEND_FORGOT_PASSWORD_OTP";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const REGISTER_UPLOAD_RESUME = "REGISTER_UPLOAD_RESUME";
export const GET_USER_PROFILE = "GET_USER_PROFILE";
export const GET_PERSONAL_INFO = "GET_PERSONAL_INFO";
export const ADD_PERSONAL_INFO = "ADD_PERSONAL_INFO";
export const GET_USER_RESUME = "GET_USER_RESUME";
export const ADD_USER_RESUME = "ADD_USER_RESUME";
export const FETCH_RECENT_JOBS = "FETCH_RECENT_JOBS";
export const APPLY_WITHOUT_REGISTRATION = "APPLY_WITHOUT_REGISTRATION";
export const APPLY_FOR_JOB = "APPLY_FOR_JOB";
export const GET_PROFILE_IMAGE = "GET_PROFILE_IMAGE";
export const UPDATE_PROFILE_IMAGE = "UPDATE_PROFILE_IMAGE";
export const ADD_ACHIEVEMENTS = "ADD_ACHIEVEMENTS";
export const ADD_EDUCATION = "ADD_EDUCATION";
export const DELETE_EDUCATION = "DELETE_EDUCATION";
export const UPDATE_EDUCATION = "UPDATE_EDUCATION";
export const ADD_EXPERIENCE = "ADD_EXPERIENCE";
export const DELETE_EXPERIENCE = "DELETE_EXPERIENCE";
export const UPDATE_EXPERIENCE = "UPDATE_EXPERIENCE";
export const ADD_USER_SKILLS = "ADD_USER_SKILLS";
export const GET_USER_JOB_STATUS = "GET_USER_JOB_STATUS";
export const SET_PASSWORD = "SET_PASSWORD";
export const OPEN_ALERT = "OPEN_ALERT";
export const ROOT_URL = "https://api.hirexl.in";
export const JOB_SEATRCH_URL = "https://meilisearch.technocube.in";
// export const BACKEND_URL = "http://localhost:3333";
export const BACKEND_URL = "https://hxl.api.staging.technocube.in";
