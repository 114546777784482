import * as React from "react";

import { Nav } from "tabler-react";

import LoginModal from "../Login";
import RegisterModal from "../Register";
import ForgotPassword from "../ForgotPassword";
import RegisterUploadResume from "../RegisterUploadResume";

interface State {
  openLogin: boolean;
  openRegister: boolean;
  openForgotPassword: boolean;
  openUploadResume: boolean;
}

interface Props {
  uRL: boolean;
}

class NavigationLoginItem extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.openLoginModal = this.openLoginModal.bind(this);
    this.openRegisterModal = this.openRegisterModal.bind(this);
    this.openForgotPasswordModal = this.openForgotPasswordModal.bind(this);
    this.openUploadResumeModal = this.openUploadResumeModal.bind(this);
    this.handleClose = this.handleClose.bind(this);

    this.state = {
      openLogin: false,
      openRegister: false,
      openForgotPassword: false,
      openUploadResume: false
    };
  }

  handleClose() {
    this.setState({
      openLogin: false,
      openRegister: false,
      openForgotPassword: false,
      openUploadResume: false
    });
  }

  openLoginModal() {
    this.setState({
      openLogin: true,
      openRegister: false,
      openForgotPassword: false,
      openUploadResume: false
    });
  }

  openForgotPasswordModal() {
    this.setState({
      openLogin: false,
      openRegister: false,
      openForgotPassword: true,
      openUploadResume: false
    });
  }

  openRegisterModal() {
    this.setState({
      openLogin: false,
      openRegister: true,
      openForgotPassword: false,
      openUploadResume: false
    });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.uRL) {
      this.openLoginModal();
    }
  }

  openUploadResumeModal() {
    this.setState({
      openLogin: false,
      openRegister: false,
      openForgotPassword: false,
      openUploadResume: true
    });
  }

  render() {
    return (
      <div className="d-flex order-lg-2 ml-auto login-items">
        <Nav.Item
          value="Upload Resume"
          onClick={this.openUploadResumeModal}
          className="nav-upload-resume"
        />
        <Nav.Item
          className="login-nav-item"
          value="Login"
          onClick={this.openLoginModal}
        />
        <span className="login-register-pipe">|</span>
        <Nav.Item
          className="register-nav-item"
          value="Register"
          onClick={this.openRegisterModal}
        />
        <LoginModal
          open={this.state.openLogin}
          onRegisterLinkClicked={this.openRegisterModal}
          onForgotPasswordClicked={this.openForgotPasswordModal}
          handleClose={this.handleClose}
        />
        <RegisterModal
          open={this.state.openRegister}
          onLoginLinkClicked={this.openLoginModal}
          handleClose={this.handleClose}
          handleUploadResume={this.openUploadResumeModal}
        />
        <ForgotPassword
          open={this.state.openForgotPassword}
          handleClose={this.handleClose}
          initialState={this.state.openForgotPassword}
        />
        <RegisterUploadResume
          open={this.state.openUploadResume}
          handleClose={this.handleClose}
          openRegisterModal={this.openRegisterModal}
        />
      </div>
    );
  }
}

export default NavigationLoginItem;
