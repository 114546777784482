import * as React from "react";

import { Grid } from "tabler-react";

import FooterSocial from "./FooterSocial.react";
import FooterLinks from "./FooterLinks.react";
import FooterLogoAndAddress from "./FooterLogoAndAddress.react";
import FooterCopyright from "./FooterCopyright.react";

interface NavObject {
  title: string;
  items: any;
  color?: string;
}

interface State {
  first: NavObject;
  second: NavObject;
}

class Footer extends React.Component<{}, State> {
  constructor(props) {
    super(props);
    this.state = {
      first: {
        title: "About",
        items: [
          { label: "About", link: "" },
          { label: "Careers", link: "" },
          { label: "Blog", link: "" },
          { label: "FAQs", link: "" },
        ],
        color: "",
      },
      second: {
        title: "My Accounts",
        items: [
          { label: "Terms & Conditions", link: "" },
          { label: "Privacy Policy", link: "privacy-policy" },
        ],
        color: "",
      },
    };
  }

  render() {
    return (
      <div className="footerbg-wrapper">
        <div className="container">
          <Grid.Row className="margin-none footerbg">
            <Grid.Col lg={3} xs={12} className="pl-0">
              <FooterLogoAndAddress />
            </Grid.Col>
            <Grid.Col lg={3} className="padding-none">
              <FooterLinks navObj={this.state.first} />
            </Grid.Col>
            <Grid.Col lg={3} className="padding-none">
              <FooterLinks navObj={this.state.second} />
            </Grid.Col>
            <Grid.Col lg={3} xs={12} className="padding-none">
              <FooterSocial />
            </Grid.Col>
          </Grid.Row>
        </div>
        <FooterCopyright />
      </div>
    );
  }
}

export default Footer;
