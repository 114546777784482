import React, { Component } from 'react'
import AuthService from './AuthService'

export default function withAuth(AuthComponent) {
  const Auth = new AuthService()
  return class Authenticated extends Component {
    constructor(props) {
      super(props)
      this.state = {
        isLoading: true
      };
    }

    componentDidMount() {
      this.setState({ isLoading: false })
    }

    render() {
      return (
        <div>
          <AuthComponent {...this.props} auth={Auth} isLoading={this.state.isLoading} />
        </div>
      )
    }
  }
}