import * as React from 'react'
import SocialLogin from 'react-social-login'

const Button = ({ children, triggerLogin, props }) => (
  <span onClick={triggerLogin} {...props}>
    {children}
  </span>
)

export default SocialLogin(Button)
