import * as React from 'react'
import { connect } from 'react-redux'

import { Icon, Card} from 'tabler-react'
import { Modal } from 'react-bootstrap'
import { bindActionCreators } from 'redux'

import { registerUploadResume } from '../../actions'
import RegisterUploadResumeFormWithValidation from './RegisterUploadResumeForm'


interface Props {
  open: boolean
  handleClose: any
  registerUploadResume:any
  openRegisterModal:any
}

interface State {
  errors:any
  files:any
  isSubmitting: boolean
}

class RegisterUploadResume extends React.Component<Props, State> {
  constructor(props){
    super(props)

    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleChange = this.handleChange.bind(this)

    this.state = {
      errors: {
        file:''
      },
      files: {},
      isSubmitting: false
    }
  }

  componentWillReceiveProps (nextProps) {
    if (nextProps.uploadedResumeUser.status && this.state.isSubmitting) {
      this.props.handleClose()
      this.props.openRegisterModal()
      this.setState({
        isSubmitting: true
      })
    }
  }

  handleSubmit(evt){
    evt.preventDefault()
    if (this.state.files.constructor === File) {
      this.setState({
        isSubmitting: true
      }, () => {
        this.props.registerUploadResume(this.state.files)
      })
    }
    
  }

  handleChange(evt){
    let validFileType = ['application/pdf', 'application/msword','application/vnd.openxmlformats-officedocument.wordprocessingml.document']
    if (evt.target.files && (validFileType.indexOf(evt.target.files[0].type) !== -1)){
      this.setState({
        errors: {
          file: ''
        },
        files: evt.target.files[0]
      })
    } else {
      this.setState({
        errors: {
          file: 'File with valid extension Pdf/doc/docx is required'
        },
        files:{}
      })
    }
    
  }
  render () {
    return(
      <Modal className='login-modal' show={this.props.open} >
        <Modal.Body>
          <Icon className='close-btn' prefix='fe' name='x' onClick={this.props.handleClose} />
          <Card className='p-6'>
            <RegisterUploadResumeFormWithValidation handleSubmit={this.handleSubmit} handleChange={this.handleChange} errors={this.state.errors} isSubmitting={this.state.isSubmitting}/>
          </Card>
        </Modal.Body>
      </Modal>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ registerUploadResume }, dispatch)
}

function mapStateToProps(state) {
  return {
    uploadedResumeUser: state.uploadedResumeUser
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RegisterUploadResume)