import * as React from "react";
import { Text } from "tabler-react";

function FooterLogoAndAddress() {
  return (
    <React.Fragment>
      <h1 className="font-logo">HireXL</h1>
      <Text>2nd floor, Plot no.4, Minarch Tower</Text>
      <Text> Sector 44, Gurugram</Text>
      <Text>Haryana - 122003</Text>
    </React.Fragment>
  );
}

export default FooterLogoAndAddress;
