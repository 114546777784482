import { JobPostingJsonLd } from "next-seo";
import moment from "moment";

import { IJob } from "types/job";

interface Props {
  data: IJob;
}

const JobPostingSEO = (props: Props) => {
  const {
    data: { title = "", createdAt = "", description = "", location = "" } = {},
  } = props;
  return (
    <JobPostingJsonLd
      datePosted={moment(createdAt).format()}
      description={description}
      hiringOrganization={{
        name: props.data.workspace ? props.data.workspace.name : "HireXL",
        sameAs: props.data.workspace ? props.data.workspace.slug : "HireXL",
      }}
      jobLocation={{
        streetAddress: location,
        addressLocality: location,
        addressRegion: location,
        postalCode: "122001",
        addressCountry: "IN",
      }}
      title={title}
      baseSalary={{
        currency: "INR",
        value: [props.data.salary.min, props.data.salary.max], // Can also be a salary range, like [40, 50]
        unitText: "YEAR",
      }}
      employmentType="FULL_TIME"
      jobLocationType="TELECOMMUTE"
      validThrough={moment(createdAt).add(30, "days").format("DD-MM-YYYY")}
      applicantLocationRequirements="INDIA"
      experienceRequirements={{
        occupational: {
          minimumMonthsOfExperience: props.data.experience.min,
        },
        experienceInPlaceOfEducation: true,
      }}
    />
  );
};

export default JobPostingSEO;
