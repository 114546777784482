import * as React from 'react'
import { connect } from 'react-redux'

import { Formik } from 'formik'
import { bindActionCreators } from 'redux'
import { LoginPage as TablerLoginPage } from 'tabler-react'

import { loginUser } from '../../actions'

class LoginPage extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      serverError: ''
    }
  }

  submitLoginForm (values, { setErrors }) {
    this.setState({
      serverError: ''
    })
    this.props.loginUser(values.email, values.password)
  }

  componentWillReceiveProps (nextProps) {
    if (nextProps.serverError){
      this.setState({
        serverError: nextProps.serverError.message
      })
    }    
  }

  render () {
    return (
      <Formik
        initialValues={{
          email: '',
          password: '',
          message: ''
        }}
        validate={values => {
          // same as above, but feel free to move this into a class method now.
          let errors = {}
          if (!values.email) {
            errors.email = 'Required'
          } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
          ) {
            errors.email = 'Invalid email address'
          }

          if (!values.password) {
            errors.password = 'Required'
          }
          return errors
        }}
        onSubmit={(
          values,
          { setErrors }
        ) => {
          this.submitLoginForm(values, { setErrors })
        }}
        render={({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting
        }) => (
          <React.Fragment>
            <TablerLoginPage
              onSubmit={handleSubmit}
              onChange={handleChange}
              onBlur={handleBlur}
              values={values}
              errors={errors}
              touched={touched}
            />
            <span className='error-feedback'>{ this.state.serverError }</span>
          </React.Fragment>
        )}
      />
    )
  }
}

function mapStateToProps(state) {
  return {
    serverError: state.serverError
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ loginUser }, dispatch)
} 

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage)
