import { OrganizationJsonLd } from "next-seo";

const OrganizationSEO = (props) => {
  const { organization: { name = "HireXL" } = {} } = props;
  return (
    <OrganizationJsonLd
      id="https://www.hirexl.in"
      type="Organization"
      legalName={name}
      name="HireXL Job Portal"
      logo="https://www.hirexl.in/static/images/hxl.jpg"
      address={{
        streetAddress: "2nd floor, Plot no.4, Minarch Tower",
        addressLocality: "Sector 44",
        addressRegion: "Gurugram",
        postalCode: "122001",
        addressCountry: "IN",
      }}
      sameAs={["https://www.hirexl.in"]}
      url="https://www.hirexl.in"
    />
  );
};
export default OrganizationSEO;
